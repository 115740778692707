import { BASE_URL } from '@config'
/**
 * 规则集
 * */
// 分页
export const GET_RULE_LIST = {
    url: `${BASE_URL}/rule-set`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 不分页
export const GET_ALL_RULE_LIST = {
    url: `${BASE_URL}/rule-set/queryRuleSetList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const GET_RULE_INFO = {
    url: `${BASE_URL}/rule-set/queryRuleSetDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const COPY_RULE = {
    url: `${BASE_URL}/rule-set/copyRuleSet`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const ADD_RULE = {
    url: `${BASE_URL}/rule-set/save/`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const DELETE_RULE = {
    url: `${BASE_URL}/rule-set/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 列表项逻辑删除
export const LOGIC_DELETE_RULE = {
    url: `${BASE_URL}/rule-set/logic-del`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const EDIT_RULE = {
    url: `${BASE_URL}/rule-set/update/`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const OPEN_OR_CLOSE_SWITCH = {
    url: `${BASE_URL}/rule-set/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 提交审核
 * */
export const SUBMIT_AUDIT_RULE_SET = {
    url: `${BASE_URL}/rule-set/applyRequst`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
