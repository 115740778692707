import axios from '@service'
import { GET_RISK_ASSESSMENT_MODEL_CONTENT_FACTOR_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 风险评估模型详情-内容tab-查询可添加的风险因子
 */
export default async function getRiskAssessmentFactorListService(params = {}, config = {}) {
    const param = {
        ...GET_RISK_ASSESSMENT_MODEL_CONTENT_FACTOR_LIST,
        ...config,
        params
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
