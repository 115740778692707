import axios from '@service'
import { GET_SELECT_RISK_CLASS_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
// 风险等级列表 无分页 ABC
export default async function getSelectRiskClassListService(payload = {}, config = {}) {
    const param = {
        ...GET_SELECT_RISK_CLASS_LIST,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
