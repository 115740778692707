import axios from '@service'
import { OPEN_OR_CLOSE_GRADE_MODEL } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

export default async function openOrCloseGradeModelService(payload = {}, config = {}) {
    const param = {
        ...OPEN_OR_CLOSE_GRADE_MODEL,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
