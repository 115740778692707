import axios from '@service'
import { SUBMIT_AUDIT_RISK_CLASS_MODEL } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 提交审核
 * @param id
 * @param config
 * @returns {Promise<*>}
 */
export default async function submitAuditRiskClassModelService(id, config = {}) {
    const param = {
        ...SUBMIT_AUDIT_RISK_CLASS_MODEL,
        ...config,
        params: {
            id
        }
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
