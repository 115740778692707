import axios from '@service'
import { CHECK_ENTERPRISE } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 数据核验提交校验企业名称及统一社会信用代码
 */
export default async function checkEnterpriseService(payload = {}, config = {}) {
    const param = {
        ...CHECK_ENTERPRISE,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
