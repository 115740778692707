import axios from '@service'
import { DELETE_RULE } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

export default async function deleteRuleService(id, config = {}) {
    let url = DELETE_RULE.url
    url += id
    const param = {
        ...DELETE_RULE,
        ...config,
        url
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
