import { BASE_URL } from '@config'

/**
 * 风险评估
 * */
// 风险评估列表
export const GET_RISK_ASSESSMENT_MODEL_LIST = {
    url: `${BASE_URL}/risk-assessment-model`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估添加
export const ADD_RISK_ASSESSMENT_MODEL = {
    url: `${BASE_URL}/risk-assessment-model/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估修改
export const EDIT_RISK_ASSESSMENT_MODEL = {
    url: `${BASE_URL}/risk-assessment-model/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估启用
export const OPEN_OR_CLOSE_RISK_ASSESSMENT_MODEL = {
    url: `${BASE_URL}/risk-assessment-model/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估删除
export const DELETE_RISK_ASSESSMENT_MODEL = {
    url: `${BASE_URL}/risk-assessment-model/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估逻辑删除
export const LOGIC_DELETE_MODEL = {
    url: `${BASE_URL}/risk-assessment-model/logic-del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估提交审核
export const SUBMIT_AUDIT_RISK_ASSESSMENT_MODEL = {
    url: `${BASE_URL}/risk-assessment-model/riskAssessmentApply`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

/**
 * 风险评估详情-内容
 * */
// 风险评估内容列表
export const RISK_ASSESSMENT_MODEL_CONTENT_LIST = {
    url: `${BASE_URL}/risk-assessment-content`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 添加风险评估内容
export const ADD_RISK_ASSESSMENT_MODEL_CONTENT = {
    url: `${BASE_URL}/risk-assessment-content/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 修改风险评估内容列表
export const EDIT_RISK_ASSESSMENT_MODEL_CONTENT = {
    url: `${BASE_URL}/risk-assessment-content/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 删除风险评估内容列表
export const DELETE_RISK_ASSESSMENT_MODEL_CONTENT = {
    url: `${BASE_URL}/risk-assessment-content/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估内容-可添加的风险因子列表
export const GET_RISK_ASSESSMENT_MODEL_CONTENT_FACTOR_LIST = {
    url: `${BASE_URL}/risk-assessment-content/listRiskFactorDefinition`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 风险评估模型复制
export const COPY_RISK_ASSESSMENT_MODEL = {
    url: `${BASE_URL}/risk-assessment-model/replication`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
