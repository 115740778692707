import { BASE_URL } from '@config'

/**
 * 风控模型
 * 列表
 * */
export const GET_RISK_CONTROL_MODEL_LIST = {
    url: `${BASE_URL}/risk-control-model-configuration`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风控模型
 * 添加
 * */
export const ADD_RISK_CONTROL_MODEL = {
    url: `${BASE_URL}/risk-control-model-configuration/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风控模型
 * 添加
 * */
export const EDIT_RISK_CONTROL_MODEL = {
    url: `${BASE_URL}/risk-control-model-configuration/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风控模型
 * 启用
 * */
export const OPEN_OR_CLOSE_RISK_CONTROL_MODEL = {
    url: `${BASE_URL}/risk-control-model-configuration/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
