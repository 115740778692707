import { BASE_URL } from '@config'

/**
* 评分模型列表
* */
export const GET_GRADE_MODEL_LIST = {
    url: `${BASE_URL}/scoring-model`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型
 * 添加
* */
export const ADD_GRADE_MODEL = {
    url: `${BASE_URL}/scoring-model/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型
 * 复制
* */
export const COPY_GRADE_MODEL = {
    url: `${BASE_URL}//scoring-model/replication`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型
 * 修改
* */
export const EDIT_GRADE_MODEL = {
    url: `${BASE_URL}/scoring-model/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型
 * 删除
* */
export const DELETE_GRADE_MODEL = {
    url: `${BASE_URL}/scoring-model/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型
 * 逻辑删除
* */
export const LOGIC_DELETE_MODEL = {
    url: `${BASE_URL}/scoring-model/logic-del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型
 * 启用/关闭
* */
export const OPEN_OR_CLOSE_GRADE_MODEL = {
    url: `${BASE_URL}/scoring-model/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

/**
* 评分模型详情-获取可添加的评分因子
* */
export const GET_CAN_ADD_GRADE_RISK_FACTOR = {
    url: `${BASE_URL}/scoring-factor/listRiskFactorDefinition`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

/**
* 评分模型-提交审核
* */
export const SUBMIT_AUDIT_GRADE_MODEL = {
    url: `${BASE_URL}/scoring-model/scoringModelApply`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型-详情-评分因子列表
* */
export const GET_GRADE_FACTOR_LIST = {
    url: `${BASE_URL}/scoring-factor`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

/**
 * 评分模型详情-添加评分因子
 * */
export const ADD_GRADE_FACTOR = {
    url: `${BASE_URL}/scoring-factor/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 评分模型详情-编辑评分因子
 * */
export const EDIT_GRADE_FACTOR = {
    url: `${BASE_URL}/scoring-factor/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 评分模型-详情-删除评分因子
* */
export const DELETE_GRADE_FACTOR = {
    url: `${BASE_URL}/scoring-factor/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
