import { BASE_URL } from '@config'
/**
 * V2.0.1修改接口
 * */
/**
 * 风险等级
 * 列表
 * */
export const GET_RISK_CLASS_LIST = {
    url: `${BASE_URL}/risk-score-model`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风险等级
 * 详情页
 * */
export const GET_RISK_CLASS_INFO = {
    url: `${BASE_URL}/risk-score-model/`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风险等级
 * 添加
 * */
export const ADD_RISK_CLASS = {
    url: `${BASE_URL}/risk-score-model/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风险等级
 * 删除
 * */
export const DELETE_RISK_FACTOR = {
    url: `${BASE_URL}/risk-score-model/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风险等级
 * 启用
 * */
export const SWITCH_RISK_CLASS = {
    url: `${BASE_URL}/risk-score-model/enableSwitch`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风险等级
 * 修改
 * */
export const EDIT_RISK_CLASS = {
    url: `${BASE_URL}/risk-score-model/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

/**
 * 风险等级
 * 提交审核
 * */
export const SUBMIT_AUDIT_RISK_CLASS_MODEL = {
    url: `${BASE_URL}/risk-score-model/riskScoreModelApply`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

/**
 * 白名单接口
 * */

/**
 * 风险等级模型
 * 列表 无分页
 * */
export const GET_RISK_CLASS_ALL_LIST = {
    url: `${BASE_URL}/risk-score-model/list`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 * 风险等级 ABC
 * 列表 无分页
 * */
export const GET_SELECT_RISK_CLASS_LIST = {
    url: `${BASE_URL}/risk-score-config/list`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}


