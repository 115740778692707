import { BASE_URL } from '@config'

/**
 * 任务列表
 *
 * */
// 任务列表
export const GET_TASK_LIST = {
    url: `${BASE_URL}/task/taskList`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

// 任务列表-任务详情
export const GET_TASK_INFO = {

    url: `${BASE_URL}/order/getRiskOrderDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 任务列表-任务详情 待录入审核
export const AUDIT_AWAIT_ENTER = {
    url: `${BASE_URL}/order/forentryAudit`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 任务列表-任务详情 待初审审核
export const AUDIT_AWAIT_FIST_AUDIT = {
    url: `${BASE_URL}/order/trialAudit`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 任务列表-任务详情 待复审审核
export const AUDIT_AWAIT_AGAIN_AUDIT = {
    url: `${BASE_URL}/order/reviewAudit`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 任务列表-任务详情 待高审审核
export const AUDIT_AWAIT_HIGH_AUDIT = {
    url: `${BASE_URL}/order/highAudit`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

// 任务列表-任务详情-风控信息 回显表单数据
export const GET_RISK_CONTROL_INFO_FORM_DATA = {
    url: `${BASE_URL}/ro-data-verification-factor-value/getRiskDataVerificationInfo`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 60000
}
// 任务列表-任务详情-风控信息  暂存当前表单数据
export const SAVE_RISK_CONTROL_INFO_CURRENT_FORM_DATA = {
    url: `${BASE_URL}/ro-data-verification-factor-value/saveStagingDataVerificationInfo`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 任务列表-任务详情-风控信息  提交所有表单数据
export const EDIT_RISK_CONTROL_INFO_FORM_DATA = {
    url: `${BASE_URL}/ro-data-verification-factor-value/updateDataVerificationInfo`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 2.0.1 数据核验 新增确认资料
export const GET_RISK_ORDER_FILE = {
    url: `${BASE_URL}/risk-order-file-value/queryRiskOrderFile`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 2.0.1 数据核验 新增设置房开
export const SET_ROOM_OPEN = {
    url: `${BASE_URL}/order/setProperties`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 2.1 数据核验提交校验企业名称及统一社会信用代码
export const CHECK_ENTERPRISE = {
    url: `${BASE_URL}/order/checkEnterprise`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}


/**
 * 任务和订单公共接口
* */
// 任务(订单)详情-黑名单列表
export const GET_BLACK_LIST = {
    url: `${BASE_URL}/order/getRiskOrderDetailBlackList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        // 'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 任务(订单)详情-风险提示列表
export const GET_RISK_WARNING_LIST = {
    url: `${BASE_URL}/order/getRiskOrderDetailRiskWarningList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 任务(订单)详情-保司规则列表
export const GET_INSURE_RULE_LIST = {
    url: `${BASE_URL}/order/getRoRuleExecutionResult`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

/**
 * 财报
 * */
// 获取财报列表
export const GET_FINANCIAL_REPORT_LIST = {
    url: `${BASE_URL}/balance-sheet/queryfinanceReport`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 获取财报单条数据 点击添加时初始化数据
export const GET_FINANCIAL_REPORT = {
    url: `${BASE_URL}/financial-report-definition/queryFinancialReport`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 获取财报已保存数据 点击添加时 是否有保存的数据
export const GET_FINANCIAL_REPORT_SAVE_INFO = {
    url: `${BASE_URL}/balance-sheet/queryTsFinancialReport`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 获取财报单条数据 修改时回显
export const GET_FINANCIAL_REPORT_INFO = {
    url: `${BASE_URL}/balance-sheet/queryfinanceDeatil`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 添加财报
export const ADD_FINANCIAL_REPORT = {
    url: `${BASE_URL}/balance-sheet/saveFinanceReport`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 暂存财报
export const SAVE_FINANCIAL_REPORT = {
    url: `${BASE_URL}/balance-sheet/tsFinancialReport`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 删除财报
export const DELETE_FINANCIAL_REPORT = {
    url: `${BASE_URL}/balance-sheet/deletefinanceReport/del`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 修改财报
export const EDIT_FINANCIAL_REPORT = {
    url: `${BASE_URL}/balance-sheet/updatefinanceReport`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 获取年份财报
export const GET_YARN_FINANCIAL_REPORT = {
    url: `${BASE_URL}/balance-sheet/queryYearFinance`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 提交选择的获取年份财报
export const ADD_SELECT_YARN_FINANCIAL_REPORT = {
    url: `${BASE_URL}/risk-order-underwriting-result/modifyRiskOrderFinance`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 是否启用年份财报
export const ENABLE_FINANCIAL_REPORT = {
    url: `${BASE_URL}/risk-order-underwriting-result/enableFinance`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 获取企业/项目资料
export const GET_ENTERPRISE_OR_PROJECT_FILE = {
    url: `${BASE_URL}/order/getRiskOrderFileList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// V2.1.0 新增获取该企业下最近完结的财报记录-并新增到该订单号中
export const COPY_ENTERPRISE_FINANCE = {
    url: `${BASE_URL}/balance-sheet/copyFinance`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 自由自核列表
export const GET_SELF_HAS_CHECK_LIST = {
    url: `${BASE_URL}/order/getScInsuranceCompanyResult`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

/**
 * 风险评估 getRiskAssess
 * */
// 获取
export const GET_RISK_ASSESS = {
    // url: `${BASE_URL}/ro-assessment-content-value/getRiskAssessmentInfoToView`,
    url: `${BASE_URL}/ro-assessment-content-value/getRiskAssessmentInfo`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 添加/修改风险评估
export const ADD_OR_EDIT_RISK_ASSESS = {
    url: `${BASE_URL}/ro-assessment-content-value/updateRiskAssessmentInfo`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 暂存风险评估
export const SAVE_RISK_ASSESS = {
    url: `${BASE_URL}/ro-assessment-content-value/saveStagingRiskAssessmentInfo`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}


/**
 * 任务指派 getAssignTasks
 * */
//  任务指派 列表
export const GET_ASSIGN_TASK_LIST = {
    url: `${BASE_URL}/task/taskAssignmentList`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 提交任务指派
export const POST_ASSIGN_TASK = {
    url: `${BASE_URL}/task/assignTasks`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

// 指派的负责人列表
export const GET_ASSIGN_USER_LIST = {
    url: `${BASE_URL}/task-allocation-config/listUserBasicInfo`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 提交任务转发
export const POST_FORWARD_TASK = {
    url: `${BASE_URL}/task/forwardTaskRequest`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 同意或者拒绝任务
export const AUDIT_TASK_FORWARD = {
    url: `${BASE_URL}/task/forwardingTaskAudit`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}


/**
 * 风险评分
 * */
export const GET_RISK_SCORE_VALUE = {
    url: `${BASE_URL}/order/queryRiskScoreFactorValue`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 历史评分
export const GET_HISTORY_SCORE = {
    url: `${BASE_URL}/order/queryRiskScore`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 修改风险评分
export const EDIT_RISK_SCORE_VALUE = {
    url: `${BASE_URL}/order/modifyRiskScore`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 预览风险评分
export const PREVIEW_SCORE = {
    url: `${BASE_URL}/order/previewScore`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
