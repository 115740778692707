import { BASE_URL } from '@config'
/**
 * 规则引擎*/

/**
 *数据核验模型-数据核验模型
 */
// 数据核验模型列表
export const GET_ADD_DATA_CHECK_MODEL_LIST = {
    url: `${BASE_URL}/data-verification-model`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-添加数据核验模型
export const ADD_DATA_CHECK_MODEL = {
    url: `${BASE_URL}/data-verification-model/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-修改数据核验模型
export const EDIT_DATA_CHECK_MODEL = {
    url: `${BASE_URL}/data-verification-model/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-删除数据核验模型
export const DELETE_DATA_CHECK_MODEL = {
    url: `${BASE_URL}/data-verification-model/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-逻辑删除数据核验模型
export const LOGIC_DELETE_MODEL = {
    url: `${BASE_URL}/data-verification-model/logic-del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-启用数据核验模型
export const OPEN_OR_CLOSE_SWITCH = {
    url: `${BASE_URL}/data-verification-model/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-单条数据核验模型查询
export const GET_DATA_CHECK_MODEL_INFO = {
    url: `${BASE_URL}/data-verification-model/`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-提交审核
export const SUBMIT_AUDIT_DATA_CHECK_MODEL = {
    url: `${BASE_URL}/data-verification-model/dataVerificationApply`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}


/** 详情页-
 * 布局分类
 * 列表
 * */
export const GET_LAYOUT_CATEGORY_LIST = {
    url: `${BASE_URL}/layout`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 布局分类新增
export const ADD_LAYOUT_CATEGORY = {
    url: `${BASE_URL}/layout/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 布局分类删除
export const DELETE_LAYOUT_CATEGORY = {
    url: `${BASE_URL}/layout/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 布局分类修改
export const EDIT_LAYOUT_CATEGORY = {
    url: `${BASE_URL}/layout/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

/**
 *数据核验模型
 * -风险因子
 */
// 风险因子列表
export const GET_DATA_CHECK_RISK_FACTOR_LIST = {
    url: `${BASE_URL}/data-verification-factor`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 数据核验模型-添加风险因子
export const ADD_DATA_CHECK_RISK_FACTOR = {
    url: `${BASE_URL}/data-verification-factor/batch-save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 数据核验模型-删除风险因子
export const DELETE_DATA_CHECK_RISK_FACTOR = {
    url: `${BASE_URL}/data-verification-factor/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 数据核验模型-查询可添加的风险因子
export const GET_CAN_ADD_RISK_FACTOR = {
    url: `${BASE_URL}/data-verification-factor/listRiskFactorDefinition`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 数据核验模型-复制
export const COPY_DATA_CHECK_MODEL = {
    url: `${BASE_URL}/data-verification-model/replication`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
