import axios from '@service'
import { GET_RISK_ASSESSMENT_MODEL_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 获取风险评估模型详情
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getRiskAssessmentModelInfoService(id, config = {}) {
    const url = GET_RISK_ASSESSMENT_MODEL_LIST.url + '/' + id
    const param = {
        ...GET_RISK_ASSESSMENT_MODEL_LIST,
        ...config,
        url
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
