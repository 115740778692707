import { BASE_URL } from '@config'
/**
 * 规则引擎*/

/**
 *智能评估模型-智能评估模型
 */
// 智能评估模型列表
export const GET_SMART_ASSESS_MODEL_LIST = {
    url: `${BASE_URL}/intelligent-evaluation-model`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型详情页
export const GET_SMART_ASSESS_MODEL_INFO = {
    url: `${BASE_URL}/intelligent-evaluation-model/`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型-添加智能评估模型
export const ADD_SMART_ASSESS_MODEL = {
    url: `${BASE_URL}/intelligent-evaluation-model/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型-修改智能评估模型
export const EDIT_SMART_ASSESS_MODEL = {
    url: `${BASE_URL}/intelligent-evaluation-model/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型-删除智能评估模型
export const DELETE_SMART_ASSESS_MODEL = {
    url: `${BASE_URL}/intelligent-evaluation-model/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型-删除智能评估模型
export const LOGIC_DELETE_MODEL = {
    url: `${BASE_URL}/intelligent-evaluation-model/logic-del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型-启用智能评估模型
export const OPEN_SWITCH = {
    url: `${BASE_URL}/intelligent-evaluation-model/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型-提交审核
export const SUBMIT_AUDIT_SMART_ASSESS_MODEL = {
    url: `${BASE_URL}/intelligent-evaluation-model/evaluationModelApply`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估模型-复制模型
export const COPY_SMART_ASSESS_MODEL = {
    url: `${BASE_URL}/intelligent-evaluation-model/copyModel`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
