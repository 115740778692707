import { BASE_URL } from '@config'
/**
 * 基础规则*/
// 分页
export const GET_RULE_LIST = {
    url: `${BASE_URL}/basic-rule`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 不分页
export const GET_ALL_RULE_LIST = {
    url: `${BASE_URL}/basic-rule/queryBasicRuleList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const GET_RULE_INFO = {
    url: `${BASE_URL}/basic-rule/queryBasicRuleDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const COPY_RULE = {
    url: `${BASE_URL}/basic-rule/copyBasicRule`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const ADD_RULE = {
    url: `${BASE_URL}/basic-rule/save/`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const DELETE_RULE = {
    url: `${BASE_URL}/basic-rule/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const EDIT_RULE = {
    url: `${BASE_URL}/basic-rule/update/`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
export const OPEN_OR_CLOSE_SWITCH = {
    url: `${BASE_URL}/basic-rule/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
