import { BASE_URL } from '@config'

/**
* 风险因子
 * 列表
* */
export const GET_RISK_FACTOR_LIST = {
    url: `${BASE_URL}/risk-factor-definition`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 风险因子
 * 列表 无分页
* */
export const GET_RISK_FACTOR_ALL_LIST = {
    url: `${BASE_URL}/risk-factor-definition/list`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 风险因子
 * 添加
* */
export const ADD_RISK_FACTOR = {
    url: `${BASE_URL}/risk-factor-definition/batch-save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
* 风险因子
 * 删除
* */
export const DELETE_RISK_FACTOR = {
    url: `${BASE_URL}/risk-factor-definition/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
